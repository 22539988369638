import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import "./diary.scss";

import Scheduler, {
  AppointmentDragging,
  View,
} from "devextreme-react/scheduler";
import Notify from "devextreme/ui/notify";

//get dx form inputs
import dxDateBox from "devextreme/ui/date_box";
import dxTextBox from "devextreme/ui/text_box";
import dxTextArea from "devextreme/ui/text_area";
import dxRadioGroup from "devextreme/ui/radio_group";
import dxSelectBox from "devextreme/ui/select_box";
import { Button } from "devextreme-react/button";
import { LoadIndicator } from "devextreme-react/load-indicator";

//get azure auth
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

//get api hook
import useFetch from "../../hooks/useFetch";
import { useLoading } from "../../hooks/LoadingContext";

//get app params (url)
import { useParams } from "react-router";

//dates to manipulated
import format from "date-fns/format";
import addMinutes from "date-fns/addMinutes";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import formatISO9075 from "date-fns/formatISO9075";
import { confirm } from "devextreme/ui/dialog";
import compareDesc from "date-fns/compareDesc";
import getHours from "date-fns/getHours";
import getMinutes from "date-fns/getMinutes";
import {
  addHours,
  subHours,
  compareAsc,
  intervalToDuration,
  isAfter,
  isBefore,
  subMinutes,
  subYears,
} from "date-fns";

//route history
import { useHistory } from "react-router-dom";
import { EmptyItem } from "devextreme-react/form";
import createActivityDetector from "activity-detector";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { WeekView } from "@devexpress/dx-react-scheduler";
import { formatDate } from "devextreme/localization";

//external data checks
import { restrictedTimes, restrictedTimesWeekend } from "./data/mac_restricted";
//import { users } from "./data/brainsafe_write";

const currentDate = new Date();
let day = currentDate.getDate();
if (day < 10) {
  day = `0${day}`;
}
let month = currentDate.getMonth() + 1;
if (month < 10) {
  month = `0${month}`;
}
let hours = currentDate.getHours();
if (hours < 10) {
  hours = `0${hours}`;
}
let mins = currentDate.getMinutes();
if (mins < 10) {
  mins = `0${mins}`;
}

const newCurrentDate = `${currentDate.getFullYear()}-${month}-${day}T${hours}:${mins}:00.000Z`;
//const newDefaultDate = `${currentDate.getFullYear()}-${month}-${day}T12:00:00.000Z`;
let errorMessage = null;

const views = [
  {
    type: "workWeek",
    name: "Work Week",
    value: "workweek",
  },
  {
    type: "week",
    name: "Week",
    value: "week",
  },
  {
    type: "day",
    name: "Day",
    value: "day",
  },
];

const min = subDays(new Date(), 15);
const max = addDays(new Date(), 30);
const minDob = subYears(new Date(), 110);
const maxDob = new Date();
const minDate = format(new Date(min), "yyyy-MM-dd");
const maxDate = format(new Date(max), "yyyy-MM-dd");

const separator = "-";

const getCurrentDate = `${currentDate.getFullYear()}${separator}${month}${separator}${day}`;

let allowedWrite = false;

const Diary = () => {
  const isAuthenticated = useIsAuthenticated();
  const { apiFetch } = useFetch();
  const { loading, setLoading } = useLoading(false);
  const [editingOptions, setEditingOptions] = useState();
  const [data, setData] = useState();
  //const [chkData, setChkData] = useState();
  const params = useParams();
  const schedulerRef = useRef(null);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [diaryMinDate, setDiaryMinDate] = useState();
  const [diaryMaxDate, setDiaryMaxDate] = useState();
  const [currentView, setCurrentView] = useState(views[1].value);

  const { accounts } = useMsal();
  const { instance } = useMsal();
  const [userRoles, setUserRoles] = useState();
  const [name, setName] = useState();
  const [chkTime, setChkTime] = useState(null);
  const [newAppsAllowed, setNewAppsAllowed] = useState([]);
  //const [bsUsers, setBsUsers] = useState([]);
  //const [writeAccessBool, setWriteAccessBool] = useState(false);

  let forPrint = schedulerRef && schedulerRef.current;

  const handlePrint = useReactToPrint({
    documentTitle: data && data.name,
    content: () => schedulerRef.current,
  });

  useEffect(() => {
    const currentUser = instance.getAllAccounts()[0];
    if (currentUser) {
      setUserRoles(getUserRoles(currentUser));
      setName(accounts[0].name);
    }
  }, [instance, accounts]);

  const getUserRoles = (currentUser) => {
    if (currentUser) {
      if (currentUser?.idTokenClaims?.roles) {
        return currentUser?.idTokenClaims?.roles;
      } else {
        return [];
      }
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    const user = instance.getAllAccounts()[0]["username"];
    let writeAccessBool = false;
    let bsUsers = [];

    getAllAppointments().then((d) => {
      bsUsers = d.users;
      writeAccessBool = d.writeAccess;

      if (isAuthenticated) {
        if (writeAccessBool) {
          if (bsUsers.indexOf(user) > -1) {
            setEditingOptions({
              allowAdding: true,
              allowDeleting: true,
              allowResizing: false,
              allowDragging: true,
              allowUpdating: true,
            });
            allowedWrite = true;
          } else {
            setEditingOptions({
              allowAdding: false,
              allowDeleting: false,
              allowResizing: false,
              allowDragging: false,
              allowUpdating: false,
            });
          }
        } else {
          setEditingOptions({
            allowAdding: true,
            allowDeleting: true,
            allowResizing: false,
            allowDragging: true,
            allowUpdating: true,
          });
          allowedWrite = true;
        }
      } else {
        setEditingOptions({
          allowAdding: false,
          allowDeleting: false,
          allowResizing: false,
          allowDragging: false,
          allowUpdating: false,
        });
      }
    });
  }, [isAuthenticated]);

  const useIdle = (options) => {
    const [isIdle, setIsIdle] = useState(false);

    useEffect(() => {
      const activityDetector = createActivityDetector(options);
      activityDetector.on("idle", () => setIsIdle(true));
      activityDetector.on("active", () => setIsIdle(false));
      return () => activityDetector.stop();
    }, []);
    return isIdle;
  };

  const getAllAppointments = async () => {
    setLoading(true);
    if (params.diaryId === ":diaryId") {
      setError(true);
      errorMessage = (
        <div className={"content-block"}>
          <h5 style={{ width: "100%", margin: "auto" }}>
            Please select a diary from the list on the home page.
          </h5>
        </div>
      );
      setLoading(false);
    } else {
      const result = await apiFetch("get-diary", "GET", {
        startDate: minDate,
        endDate: maxDate,
        diaryId: params.diaryId,
      });

      if (result) {
        setData(result);

        //set diary min / max
        //separate min date
        const minDay = format(new Date(min), "d");
        const minMonth = format(new Date(min), "MM") - 1;
        const minYear = format(new Date(min), "yyyy");
        //separate max date
        const maxDay = format(new Date(max), "d");
        const maxMonth = format(new Date(max), "MM") - 1;
        const maxYear = format(new Date(max), "yyyy");

        let startTimeHour = result.diaryStartTime;
        let endTimeHour = result.diaryEndTime;
        let startTimeLength = result.diaryStartTime.toString();
        let endTimeLength = result.diaryEndTime.toString();

        if (startTimeLength.length > 1) {
          startTimeHour = startTimeLength.substring(0, 2);
          setDiaryMinDate(
            formatISO9075(
              new Date(minYear, minMonth, minDay, startTimeHour, 30, 0)
            )
          );
        } else {
          setDiaryMinDate(
            formatISO9075(
              new Date(minYear, minMonth, minDay, startTimeHour, 0, 0)
            )
          );
        }

        if (endTimeLength.length > 1) {
          endTimeHour = endTimeLength.substring(0, 2);
        }

        setDiaryMaxDate(
          formatISO9075(
            new Date(maxYear, maxMonth, maxDay, endTimeHour, result.timeSlot, 0)
          )
        );

        setCurrentView(result.defaultView);

        setLoading(false);
      } else {
        setError(true);
        errorMessage = (
          <div className={"content-block"}>
            <h5 style={{ width: "100%", margin: "auto" }}>
              Error retrieving appointments please try again later or contact
              your system administrator.
            </h5>
          </div>
        );
      }
      return result;
    }
  };

  const dinnerSlot = (date) => {
    let hours = date.getHours();
    let dinnerStartTime = data && data.lunchBreakStart;
    let dinnerLength = data && data.lunchBreak;
    let lunchBreakEnd = 0;

    if (hours === dinnerStartTime) {
      lunchBreakEnd = addMinutes(date, dinnerLength);
    }

    return hours === lunchBreakStart && hours <= lunchBreakEnd.getHours();
  };

  const giveDinnerSlotIcon = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let dinnerStartTime = data ? data.lunchBreakStart : 0;
    let dinnerLength = data ? data.lunchBreak : 0;
    let lunchBreakEnd = 0;

    if (hours === dinnerStartTime) {
      lunchBreakEnd = addMinutes(date, dinnerLength);
    }

    return (
      hours === dinnerStartTime &&
      minutes === 0 &&
      hours <= lunchBreakEnd.getHours() &&
      minutes === 0
    );
  };

  const changeCellDuration = (value) => {
    switch (value) {
      case 15:
        value = 15;
        break;
      case 30:
        value = 15;
        break;
      default:
        value = diaryTimeSlot;
        break;
    }
    return value;
  };

  const diaryStartTime = data ? data.diaryStartTime : 8;
  const diaryEndTime = data ? data.diaryEndTime : 17.5;
  const diaryTimeSlot = data ? data.timeSlot : 15;
  const hasLunchBreak = data ? data.hasLunchBreak : false;
  const lunchBreakStart = data ? data.lunchBreakStart : 0;
  const lunchBreak = data ? data.lunchBreak : 0;
  const lunchBreakStart2 = data ? data.lunchBreakStart2 : 0;
  const lunchBreak2 = data ? data.lunchBreak2 : 0;
  const lunchBreakStart3 = data ? data.lunchBreakStart3 : 0;
  const lunchBreak3 = data ? data.lunchBreak3 : 0;
  const cellDuration = changeCellDuration(diaryTimeSlot);
  const appTimeSlotDefault = diaryTimeSlot;

  let cellAppLimit = data ? data.limitPerApp : 1;
  let recurrenceRule = data ? data.recurrenceRule : "";

  if (currentView === "month") {
    cellAppLimit = 1;
  } else {
    cellAppLimit = data ? data.limitPerApp : 1;
  }

  const bookings = (model) => {
    const { appointmentData } = model.data;
    let addedToPas = "";
    let startTimeHour = getHours(new Date(appointmentData.startDate));
    let startTimeMinute = getMinutes(new Date(appointmentData.startDate));
    let endTimeHour = getHours(new Date(appointmentData.endDate));
    let endTimeMinute = getMinutes(new Date(appointmentData.endDate));
    let displayReason = appointmentData.rAttend;
    let colour = appointmentData.colour;
    let addedByForDiaryView = appointmentData.addedBy
      ? appointmentData.addedBy.split("(")
      : ["", ""];
    //let createdByDate = appointmentData ? format(new Date(appointmentData.createdBy.date), "yyyy-MM-dd") : null;
    let dobForDiaryView = appointmentData.dob
      ? format(new Date(appointmentData.dob), "yyyy-MM-dd")
      : null;
    let nhsNoView = appointmentData.tNumber;
    let tNoView = appointmentData.nhsNumber;
    let idView;
    let owner = appointmentData.owner ? appointmentData.owner : "";

    if (nhsNoView === undefined || nhsNoView === "") {
      idView = tNoView;
    } else {
      idView = nhsNoView;
    }

    if (tNoView === undefined || tNoView === "") {
      idView = nhsNoView;
    } else {
      idView = tNoView;
    }

    let difference = intervalToDuration({
      start: new Date(appointmentData.startDate),
      end: new Date(appointmentData.endDate),
    });

    if (startTimeHour === 0) {
      startTimeHour = `0${startTimeHour}`;
    }
    if (startTimeMinute === 0) {
      startTimeMinute = `0${startTimeMinute}`;
    }
    if (endTimeHour === 0) {
      endTimeHour = `0${endTimeHour}`;
    }
    if (endTimeMinute === 0) {
      endTimeMinute = `0${endTimeMinute}`;
    }
    const startTime = `${startTimeHour}:${startTimeMinute}`;
    const endTime = `${endTimeHour}:${endTimeMinute}`;

    let firstNameDisplay = `${appointmentData.firstName}`;
    let firstStringLength = firstNameDisplay.length;

    if (firstStringLength > 10) {
      firstNameDisplay = firstNameDisplay.substring(0, 15) + "..";
    }

    let secondNameDisplay = `${appointmentData.surName}`;
    let secondStringLength = secondNameDisplay.length;

    if (secondStringLength > 10) {
      secondNameDisplay = secondNameDisplay.substring(0, 15) + "..";
    }

    if (appointmentData.addedToPas) {
      addedToPas = "addedToPas";
    }

    if (displayReason !== undefined) {
      let displayReasonToText = `${appointmentData.rAttend}`;
      let reasonLength = displayReasonToText.length;
      if (reasonLength > 25) {
        displayReason = displayReason.substring(0, 25) + "..";
      } else {
        displayReason = displayReasonToText;
      }
    } else {
      displayReason = "";
    }

    let previewValue,
      previewValueReason,
      smallValue,
      previewName,
      indicatorWeek,
      indicatorWorkWeek,
      indicatorDay,
      indicatorContainerDay = "";

    switch (currentView) {
      case "day":
        previewValue = "bookings-day-preview-value";
        indicatorDay = "indicator-day";
        indicatorContainerDay = "indicator-container-day";

        return (
          <div className={`bookings-preview day ${colour}`}>
            <div
              className={`indicator-container ${indicatorWeek} ${indicatorWorkWeek} ${indicatorContainerDay}`}
            >
              <div className={`indicator ${addedToPas} ${indicatorDay}`}></div>
            </div>
            <div className={`bookings-preview-value ${previewValue}`}>
              {format(new Date(appointmentData.startDate), "yyyy-MM-dd")}{" "}
              {startTime} by {addedByForDiaryView[0]}
            </div>
            <div className={`bookings-preview-value ${previewValue} `}>
              <span className={`${previewValue} ${previewName} ${smallValue}`}>
                {idView} {firstNameDisplay} {secondNameDisplay}{" "}
                {dobForDiaryView}
              </span>
              <span className={`${previewValue}`}> </span>
            </div>
            <div
              className={`bookings-preview-value ${previewValue} ${previewValueReason}`}
            >
              <div>{displayReason}</div>
              <div>
                {"Owner:"} {owner}
              </div>
            </div>
          </div>
        );
      case "week":
        indicatorWeek = "indicator-week";
        previewValue = "bookings-week-preview-value";
        previewName = "bookings-week-preview-name";
        if (difference.minutes <= data.timeSlot) {
          smallValue = "bookings-small-hide-value";
        }
        return (
          <div className={`bookings-preview week ${colour}`}>
            <div
              className={`indicator-container ${indicatorWeek} ${indicatorWorkWeek} ${indicatorContainerDay}`}
            >
              <div className={`indicator ${addedToPas} ${indicatorDay}`}></div>
            </div>
            <div className={`bookings-preview-value ${previewValue}`}>
              {startTime} {firstNameDisplay} {secondNameDisplay}
            </div>
            <div
              className={`bookings-preview-value ${previewValue} ${previewValueReason}`}
            >
              <div>{displayReason}</div>
              <div>
                {"Owner:"} {owner}
              </div>
            </div>
          </div>
        );
      case "workWeek":
        indicatorWorkWeek = "indicator-work-week";
        previewValue = "bookings-week-preview-value";
        if (difference.minutes <= data.timeSlot) {
          smallValue = "bookings-small-hide-value";
        }
        return (
          <div className={`bookings-preview workweek ${colour}`}>
            <div
              className={`indicator-container ${indicatorWeek} ${indicatorWorkWeek} ${indicatorContainerDay}`}
            >
              <div className={`indicator ${addedToPas} ${indicatorDay}`}></div>
            </div>
            <div className={`bookings-preview-value ${previewValue}`}>
              {startTime} {firstNameDisplay} {secondNameDisplay}
            </div>
            <div
              className={`bookings-preview-value ${previewValue} ${previewValueReason}`}
            >
              <div>{displayReason}</div>
              <div>
                {"Owner:"} {owner}
              </div>
            </div>
          </div>
        );
      case "month":
        previewValue = "bookings-month-preview-value";
        previewValueReason = " bookings-month-preview-value-reason";
        return (
          <div className={`bookings-preview ${colour}`}>
            <div
              className={`indicator-container ${indicatorWeek} ${indicatorWorkWeek} ${indicatorContainerDay}`}
            >
              <div className={`indicator ${addedToPas} ${indicatorDay}`}></div>
            </div>
            <div className={`bookings-preview-value ${previewValue}`}>
              {startTime}
            </div>
            <div className={`bookings-preview-value ${previewValue} `}>
              <span className={`${previewValue} ${previewName} ${smallValue}`}>
                {firstNameDisplay}
              </span>{" "}
              <span className={`${previewValue}`}>{secondNameDisplay}</span>
            </div>
            <div
              className={`bookings-preview-value ${previewValue} ${previewValueReason}`}
            >
              <div>{displayReason}</div>
              <div>
                {"Owner:"} {owner}
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className={`bookings-preview ${colour}`}>
            <div
              className={`indicator-container ${indicatorWeek} ${indicatorWorkWeek} ${indicatorContainerDay}`}
            >
              <div className={`indicator ${addedToPas} ${indicatorDay}`}></div>
            </div>
            <div className={`bookings-preview-value ${previewValue}`}>
              {startTime}
            </div>
            <div className={`bookings-preview-value ${previewValue} `}>
              <span className={`${previewValue} ${previewName} ${smallValue}`}>
                {firstNameDisplay}
              </span>{" "}
              <span className={`${previewValue}`}>{secondNameDisplay}</span>
            </div>
            <div
              className={`bookings-preview-value ${previewValue} ${previewValueReason}`}
            >
              <div>{displayReason}</div>
              <div>
                {"Owner:"} {owner}
              </div>
            </div>
          </div>
        );
    }
  };

  const bookingTooltip = (model) => {
    const { appointmentData } = model;

    let addedToPasClass = "";
    let numberToDisplay = "";
    let displayReason = appointmentData.rAttend;
    let addedBy = appointmentData.addedBy;
    let owner = appointmentData.owner;

    const format_nhsNumber = (number) => {
      if (number !== undefined && number !== null && number !== "") {
        let convert = number.toString();
        let firstpart = convert.slice(0, 3);
        let secondpart = convert.slice(3, 6);
        let thirdpart = convert.slice(6, 10);
        return firstpart + " " + secondpart + " " + thirdpart;
      } else {
        return null;
      }
    };

    const startTime = formatISO9075(new Date(appointmentData.startDate), {
      representation: "time",
    });

    const endTime = formatISO9075(new Date(appointmentData.endDate), {
      representation: "time",
    });

    let iconColour = appointmentData.colour ? appointmentData.colour : null;

    switch (iconColour) {
      case "New - Red":
        iconColour = "iconRed";
        break;
      case "ED FU - Turquoise":
        iconColour = "iconTurquoise";
        break;
      case "FU Clinical Review - Orange":
        iconColour = "iconOrange";
        break;
      case "FU Scan - Purple":
        iconColour = "iconPurple";
        break;
      case "Procedure - Pink":
        iconColour = "iconPink";
        break;
      case "Virtual Assessment - Yellow":
        iconColour = "iconYellow";
        break;
      case "none":
        iconColour = null;
        break;
      default:
        iconColour = null;
        break;
    }

    if (appointmentData.addedToPas) {
      addedToPasClass = "addedToPasColor";
    }

    if (appointmentData.tNumber) {
      numberToDisplay = appointmentData.tNumber;
    } else {
      numberToDisplay = format_nhsNumber(appointmentData.nhsNumber);
    }

    if (addedBy !== undefined) {
      addedBy = addedBy.slice(0, 10);
    }

    return (
      <div className="booking-tooltip">
        <div className="booking-header-con">
          <div className="booking-header-icon">
            <div className="icon-con">
              <div className="icon">
                <i
                  className={`dx-icon-isnotblank ${iconColour} ${addedToPasClass}`}
                ></i>
                <p>
                  {numberToDisplay ? numberToDisplay : null} |{" "}
                  {appointmentData.firstName ? appointmentData.firstName : null}{" "}
                  {appointmentData.surName ? appointmentData.surName : null}
                </p>
              </div>
              {(appointmentData.addedToPas === false ||
                appointmentData.addedToPas === null ||
                appointmentData.addedToPas === undefined) &&
              allowedWrite === true ? (
                <Button
                  key={"addedToPas"}
                  hint={"Mark as added to PAS"}
                  icon={"check"}
                  useSubmitBehavior={false}
                  onClick={(e) => {
                    addedToPASButton(appointmentData, e);
                  }}
                />
              ) : null}
              {appointmentData.addedToPas === true && allowedWrite === true ? (
                <Button
                  key={"removeFromPas"}
                  hint={"Remove mark as added to PAS"}
                  icon={"clear"}
                  useSubmitBehavior={false}
                  onClick={(e) => {
                    removeFromPASButton(appointmentData, e);
                  }}
                />
              ) : null}
              {allowedWrite === true ? (
                <Button
                  key={"delBtn"}
                  hint={"Remove appointment"}
                  icon="trash"
                  useSubmitBehavior={false}
                  onClick={(e) => {
                    delButton(appointmentData, e);
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className={`booking-info-container`}>
          <div className={`booking-info`}>
            <div className="booking-sub-con">
              <div className="booking-sub-title">StartDate:</div>
              <div className="booking-sub-content">{startTime} </div>
            </div>
            <div className="booking-sub-con">
              <div className="booking-sub-title">EndDate:</div>
              <div className="booking-sub-content">{endTime} </div>
            </div>
            <div className="booking-sub-con">
              <div className="booking-sub-title">Reason:</div>
              <div className="booking-sub-content">
                {displayReason ? displayReason : null}{" "}
              </div>
            </div>
            <div className="booking-sub-con">
              <div className="booking-sub-title">Added By:</div>
              <div className="booking-sub-content">
                {addedBy ? addedBy : null}{" "}
              </div>
            </div>
            <div className="booking-sub-con">
              <div className="booking-sub-title">Owner:</div>
              <div className="booking-sub-content">{owner ? owner : null} </div>
            </div>
          </div>
          <div className={`booking-info`}>
            <div className="booking-sub-con">
              <div className="booking-sub-title">Category:</div>
              <div className="booking-sub-content">
                {appointmentData.colour ? appointmentData.colour : null}{" "}
              </div>
            </div>
            <div className="booking-sub-con">
              <div className="booking-sub-title">Referrer:</div>
              <div className="booking-sub-content">
                {appointmentData.wRefer ? appointmentData.wRefer : null}{" "}
              </div>
            </div>
            <div className={`booking-sub-con`}>
              <div className={`booking-sub-title`}>Contact Details:</div>
              <div className={`booking-sub-content`}>
                {appointmentData.contactNumber
                  ? appointmentData.contactNumber
                  : null}{" "}
              </div>
            </div>
            <div className={`booking-sub-con`}>
              <div className={`booking-sub-title`}>Notes:</div>
              <div className={`booking-sub-content`}>
                {appointmentData.notesField ? appointmentData.notesField : null}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isValidNhsNumber = (nhsNumber) => {
    let isValid = false;

    if (nhsNumber.length === 10) {
      let total = 0;
      let i = 0;
      for (i = 0; i <= 8; i++) {
        let digit = nhsNumber.substr(i, 1);
        let factor = 10 - i;
        total += digit * factor;
      }

      let checkDigit = 11 - (total % 11);

      if (checkDigit === 11) {
        checkDigit = 0;
      }

      let lastDigit = parseInt(nhsNumber.substr(9, 1), 10);

      if (checkDigit === lastDigit) {
        isValid = true;
      }

      if (nhsNumber === "0000000000") {
        isValid = false;
      }
    }
    return isValid;
  };

  const checkCell = (e) => {
    let newStartDate = subHours(new Date(e.startDate), 1);
    let cellBefore = subMinutes(newStartDate, 15);

    let stringCellBefore = format(
      new Date(cellBefore),
      "yyyy-MM-dd'T'HH:mm:ss.'000Z'"
    );

    //Check for cell before
    let filterNewAppsCellBefore = newAppsAllowed.filter(
      (entry) => entry.startDate === stringCellBefore
    );

    let stringCell = format(
      new Date(newStartDate),
      "yyyy-MM-dd'T'HH:mm:ss.'000Z'"
    );

    //Check current cell
    let filterNewAppsCell = newAppsAllowed.filter(
      (entry) => entry.startDate === stringCell
    );

    let cellAfter = addMinutes(newStartDate, 15);

    let stringCellAfter = format(
      new Date(cellAfter),
      "yyyy-MM-dd'T'HH:mm:ss.'000Z'"
    );

    //Check cell after
    let filterNewAppsCellAfter = newAppsAllowed.filter(
      (entry) => entry.startDate === stringCellAfter
    );

    if(data.timeSlot === 15 ){
      if(filterNewAppsCell.length >= data.limitPerApp){
        return true;
      }else{
        return false;
      }
    }else{
      //picked up more or equal to apps limit
      if(filterNewAppsCell.length >= data.limitPerApp){
        return true;
      }else if(filterNewAppsCell.length+filterNewAppsCellBefore.length >= data.limitPerApp){
        return true;
      }else if(filterNewAppsCell.length+filterNewAppsCellAfter.length >= data.limitPerApp){
        return true;
      }else{
        return false;
      }
    };
  };

  const fetchNewApps = async (e) => {
    let newStartDate = subHours(new Date(e.startDate), 1);

    let stringStartDate = format(
      new Date(newStartDate),
      "yyyy-MM-dd'T'HH:mm:ss.'000Z'"
    );

    let dateOnly = stringStartDate.substring(0, 10);

    let startOfDay = `${dateOnly}T06:00:00.000Z`;
    let endOfDay = `${dateOnly}T22:00:00.000Z`;

    //query the db for the day
    const result = await apiFetch("get-diary", "GET", {
      startDate: startOfDay,
      endDate: endOfDay,
      diaryId: params.diaryId,
    });

    setNewAppsAllowed(result.appointments);
    return true;
  };

  const onCellClick = (e) => {
    let currentTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:00.'000Z'");
    let compareTime = compareAsc(new Date(currentTime), new Date(chkTime));

    fetchNewApps(e.cellData);
    /*if (chkTime === null) {
      currentTime = addMinutes(new Date(currentTime), 1);
      setChkTime(currentTime);
      fetchNewApps(e.cellData);
      //checkCell(e.cellData);
    } else if (chkTime != null) {
      switch (compareTime) {
        case 1:
          currentTime = addMinutes(new Date(currentTime), 1);
          setChkTime(currentTime);
          fetchNewApps(e.cellData);
          //checkCell(e.cellData);
          break;
        case 0:
          currentTime = addMinutes(new Date(currentTime), 1);
          setChkTime(currentTime);
          fetchNewApps(e.cellData);
          //checkCell(e.cellData);
          break;
        default:
          //checkCell(e.cellData);
          break;
      }

    }*/
  };

  const onAppointmentFormOpening = (e) => {
    let appTimeSlot = data.timeSlot;
    const appNhsNumber = e.appointmentData.nhsNumber;
    const appTNumber = e.appointmentData.tNumber;
    const idsRequired = data.idsRequired;
    const addRequired = data.addRequired;

    let readOnlyInput = false;
    let newStartDate = e.appointmentData.startDate;
    let newEndDate = e.appointmentData.endDate;
    let itIsRestricted = false;

    let stringStartDate = format(
      new Date(newStartDate),
      "yyyy-MM-dd'T'HH:mm:ss.'000Z'"
    );
    let stringEndDate = format(
      new Date(newEndDate),
      "yyyy-MM-dd'T'HH:mm:ss.'000Z'"
    );

    let getTheDay = new Date(newStartDate).getDay();
    let dateOnly = stringStartDate.substring(0, 10);
    let updateEndDate = stringEndDate.substring(0, 11);
    let updateEndDateHour = stringEndDate.substring(11, 13);
    let updateEndDateMin = stringEndDate.substring(14, 16);
    const newRestrictedTimes = [];
    const newRestrictedTimesWeekend = [];
    let filteredTime = [];
    let restrictedTimeSlotDefault = appTimeSlot;

    recurrenceRule = recurrenceRule ? "undefined" : "";

    let appByTime = data ? data.appByTime : false;

    let appByTimeVals = data ? data.appByTimeVals : null;

    function lastSunday(year, month) {
      var date = new Date(year, month, 1, 12);
      let weekday = date.getDay();
      let dayDiff = weekday === 0 ? 7 : weekday;
      let lastSunday = date.setDate(date.getDate() - dayDiff);
      return date.toDateString();
    }

    if (data.restrictedTimes === true) {
      let isAfterTwelve = compareAsc(
        new Date(e.appointmentData.startDate),
        new Date(`${dateOnly}T10:45:00.000Z`)
      );

      if (isAfterTwelve === 1) {
        restrictedTimeSlotDefault = 30;

        if (restrictedTimeSlotDefault === 30) {
          appTimeSlot = 30;
          appTimeSlot = appTimeSlot.toString();

          if (e.appointmentData.id === undefined) {
            if (updateEndDateMin === "45") {
              updateEndDateHour = parseInt(updateEndDateHour);
              updateEndDateHour =
                updateEndDateHour < 10
                  ? `0${updateEndDateHour.toString()}`
                  : updateEndDateHour.toString();
              newEndDate = `${dateOnly}T${updateEndDateHour}:00:00.000Z`;
            } else {
              if (updateEndDateMin === "00") {
                updateEndDateHour = parseInt(updateEndDateHour) - 1;
                updateEndDateHour =
                  updateEndDateHour < 10
                    ? `0${updateEndDateHour.toString()}`
                    : updateEndDateHour.toString();
                newEndDate = `${dateOnly}T${updateEndDateHour}:15:00.000Z`;
              } else {
                updateEndDateHour = parseInt(updateEndDateHour) - 1;
                updateEndDateHour =
                  updateEndDateHour < 10
                    ? `0${updateEndDateHour.toString()}`
                    : updateEndDateHour.toString();
                newEndDate = `${dateOnly}T${updateEndDateHour}:${appTimeSlot}:00.000Z`;
              }
            }
          }
        }
      } else {
        appTimeSlot = 15;
      }
    }

    let isExisting = false;
    let startDateReadOnly = false;
    let endDateReadOnly = false;

    const taken = checkCurrentApp(
      e.appointmentData.startDate,
      e.appointmentData.endDate,
      data.limitPerApp
    );

    if (e.appointmentData.diaryId) {
      isExisting = true;
      newStartDate = e.appointmentData.startDate;
      newEndDate = e.appointmentData.endDate;
    }

    const displayNotifyDate = format(new Date(newStartDate), "PPPPpp");

    if (data.restrictedTimes === true) {
      switch (getTheDay) {
        case 0:
          restrictedTimesWeekend.map((item) => {
            return newRestrictedTimesWeekend.push(`${dateOnly}${item}`);
          });

          filteredTime = newRestrictedTimesWeekend.filter(
            (entry) =>
              entry ===
              format(new Date(newStartDate), "yyyy-MM-dd'T'HH:mm:ss.'000Z'")
          );

          if (filteredTime.length > 0) {
            itIsRestricted = true;
          } else {
            appTimeSlot = 30;
            appTimeSlot = appTimeSlot.toString();

            if (updateEndDateMin === "45") {
              updateEndDateHour = parseInt(updateEndDateHour);
              updateEndDateHour =
                updateEndDateHour < 10
                  ? `0${updateEndDateHour.toString()}`
                  : updateEndDateHour.toString();
              newEndDate = `${dateOnly}T${updateEndDateHour}:00:00.000Z`;
            } else {
              updateEndDateHour = parseInt(updateEndDateHour) - 1;
              updateEndDateHour =
                updateEndDateHour < 10
                  ? `0${updateEndDateHour.toString()}`
                  : updateEndDateHour.toString();
              newEndDate = `${dateOnly}T${updateEndDateHour}:${appTimeSlot}:00.000Z`;
            }
          }
          break;
        case 6:
          restrictedTimesWeekend.map((item) => {
            return newRestrictedTimesWeekend.push(`${dateOnly}${item}`);
          });

          filteredTime = newRestrictedTimesWeekend.filter(
            (entry) =>
              entry ===
              format(new Date(newStartDate), "yyyy-MM-dd'T'HH:mm:ss.'000Z'")
          );

          if (filteredTime.length > 0) {
            itIsRestricted = true;
          } else {
            appTimeSlot = 30;
            appTimeSlot = appTimeSlot.toString();

            if (updateEndDateMin === "45") {
              updateEndDateHour = parseInt(updateEndDateHour);
              updateEndDateHour =
                updateEndDateHour < 10
                  ? `0${updateEndDateHour.toString()}`
                  : updateEndDateHour.toString();
              newEndDate = `${dateOnly}T${updateEndDateHour}:00:00.000Z`;
            } else {
              updateEndDateHour = parseInt(updateEndDateHour) - 1;
              updateEndDateHour =
                updateEndDateHour < 10
                  ? `0${updateEndDateHour.toString()}`
                  : updateEndDateHour.toString();
              newEndDate = `${dateOnly}T${updateEndDateHour}:${appTimeSlot}:00.000Z`;
            }
          }
          break;
        default:
          let isAfterTwelve = compareAsc(
            new Date(newStartDate),
            new Date(`${dateOnly}T11:00:00.000Z`)
          );

          if (isAfterTwelve >= 0) {
            restrictedTimes.map((item) => {
              return newRestrictedTimes.push(`${dateOnly}${item}`);
            });

            filteredTime = newRestrictedTimes.filter(
              (entry) =>
                entry ===
                format(new Date(newStartDate), "yyyy-MM-dd'T'HH:mm:ss.'000Z'")
            );

            if (filteredTime.length > 0) {
              itIsRestricted = true;
            } else {
              appTimeSlot = appTimeSlot.toString();

              if (updateEndDateMin === "45") {
                updateEndDateHour = parseInt(updateEndDateHour);
                updateEndDateHour =
                  updateEndDateHour < 10
                    ? `0${updateEndDateHour.toString()}`
                    : updateEndDateHour.toString();
                newEndDate = `${updateEndDate}${updateEndDateHour}:00:00.000Z`;
              } else {
                updateEndDateHour = parseInt(updateEndDateHour) - 1;
                updateEndDateHour =
                  updateEndDateHour < 10
                    ? `0${updateEndDateHour.toString()}`
                    : updateEndDateHour.toString();
                newEndDate = `${dateOnly}T${updateEndDateHour}:${appTimeSlot}:00.000Z`;
              }
            }
          }
          break;
      }

      if (itIsRestricted === true) {
        e.cancel = true;
        showToast(
          "Warning",
          `${filteredTime[0]} slot is currently not available.`,
          "warning"
        );
      }
    }

    if (isDinner(new Date(newStartDate)) === true) {
      e.cancel = true;
      showToast("Warning", "Break slot is currently not available.", "warning");
    } else if (isDinner2(new Date(newStartDate)) === true) {
      e.cancel = true;
      showToast("Warning", "Break slot is currently not available.", "warning");
    } else if (isDinner3(new Date(newStartDate)) === true) {
      e.cancel = true;
      showToast("Warning", "Break slot is currently not available.", "warning");
    } else if (data.restrictedDays === true) {
      const getDay = new Date(newStartDate).getDay();
      const dayOne = data ? data.rDays.dayOne : null;
      const dayTwo = data ? data.rDays.dayTwo : null;
      const dayThree = data ? data.rDays.dayThree : null;
      const dayFour = data ? data.rDays.dayFour : null;
      const dayFive = data ? data.rDays.dayFive : null;
      const daySix = data ? data.rDays.daySix : null;

      let dateToDisplay = format(new Date(newStartDate), "do MMM");

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      let dayName = days[getDay];

      switch (getDay) {
        case dayOne:
          e.cancel = true;
          showToast(
            "Warning",
            `${dayName} ${dateToDisplay} is currently not available.`,
            "warning"
          );
          break;

        case dayTwo:
          e.cancel = true;
          showToast(
            "Warning",
            `${dayName} ${dateToDisplay} is currently not available.`,
            "warning"
          );
          break;

        case dayThree:
          e.cancel = true;
          showToast(
            "Warning",
            `${dayName} ${dateToDisplay} is currently not available.`,
            "warning"
          );
          break;

        case dayFour:
          e.cancel = true;
          showToast(
            "Warning",
            `${dayName} ${dateToDisplay} is currently not available.`,
            "warning"
          );
          break;
        case dayFive:
          e.cancel = true;
          showToast(
            "Warning",
            `${dayName} ${dateToDisplay} is currently not available.`,
            "warning"
          );
          break;
        case daySix:
          e.cancel = true;
          showToast(
            "Warning",
            `${dayName} ${dateToDisplay} is currently not available.`,
            "warning"
          );
          break;
        default:
          if (data.appByTime === true) {
            let getAppTime = new Date(newStartDate);
            let getCalcDate = new Date(
              `${dateOnly}T${appByTimeVals.after.time}:00.000Z`
            );
            let getCurrentYear = new Date(
              e.appointmentData.startDate
            ).getFullYear();

            let startOfBst = lastSunday(getCurrentYear, 3);
            let endOfBst = lastSunday(getCurrentYear, 10);

            if (
              isAfter(new Date(newStartDate), new Date(startOfBst)) &&
              isBefore(new Date(newStartDate), new Date(endOfBst))
            ) {
              let removeHr = subHours(getCalcDate, 1);

              if (isBefore(getAppTime, removeHr)) {
                e.popup.option(
                  "title",
                  e.appointmentData.title
                    ? e.appointmentData.title +
                        " For " +
                        appByTimeVals.before.text
                    : "Create a new appointment for " +
                        appByTimeVals.before.text
                );

                showToast(
                  "Alert",
                  `This is appointment for ` + appByTimeVals.before.text,
                  "info"
                );
              } else {
                e.popup.option(
                  "title",
                  e.appointmentData.title
                    ? e.appointmentData.title +
                        " For " +
                        appByTimeVals.after.text
                    : "Create a new appointment for " + appByTimeVals.after.text
                );

                showToast(
                  "Alert",
                  `This is appointment for ` + appByTimeVals.after.text,
                  "info"
                );
              }
            } else {
              if (isBefore(getAppTime, getCalcDate)) {
                e.popup.option(
                  "title",
                  e.appointmentData.title
                    ? e.appointmentData.title +
                        " For " +
                        appByTimeVals.before.text
                    : "Create a new appointment for " +
                        appByTimeVals.before.text
                );
              } else {
                e.popup.option(
                  "title",
                  e.appointmentData.title
                    ? e.appointmentData.title +
                        " For " +
                        appByTimeVals.after.text
                    : "Create a new appointment for " + appByTimeVals.after.text
                );
              }
            }
          }
          break;
      }
    }

    if( checkCell(e.appointmentData) ){
      showToast(
        "Limit reached",
        `please refresh the diary to display the latest appointments or select a different time slot..`,
        "info"
      );
      e.cancel = true;
    }else if (taken === true && isExisting === false) {
      showToast(
        "Limit Reached",
        `Sorry the appointment limit has been reached for ${displayNotifyDate} please select a different time slot..`,
        "info"
      );
      e.cancel = true;
    }

    e.popup.option({
      showTitle: true,
      dragEnabled: false,
      position: {
        at: "center",
        my: "center",
      },
      showCloseButton: true,
      minWidth: "90vw",
      minHeight: "75vh",
      maxHeight: "90vh",
    });

    if (data.appByTime === false || data.appByTime === undefined) {
      e.popup.option(
        "title",
        e.appointmentData.title
          ? e.appointmentData.title
          : "Create a new appointment"
      );
    }

    if (e.appointmentData.id !== undefined) {
      readOnlyInput = false;
      if (data.restrictedTimes === true) {
        startDateReadOnly = false;
        endDateReadOnly = true;
      } else {
        startDateReadOnly = readOnlyInput;
        endDateReadOnly = readOnlyInput;
      }
    } else {
      readOnlyInput = true;
      startDateReadOnly = readOnlyInput;
      endDateReadOnly = readOnlyInput;
    }

    if (e.appointmentData.newAppBtn === true) {
      readOnlyInput = false;
      if (data.restrictedTimes === true) {
        startDateReadOnly = false;
        endDateReadOnly = true;
      } else {
        startDateReadOnly = readOnlyInput;
        endDateReadOnly = readOnlyInput;
      }
    }

    const { form } = e;
    //newStartDate = e.appointmentData.startDate;
    newEndDate = addMinutes(new Date(newStartDate), appTimeSlot);
    form.updateData("startDate", newStartDate);
    form.updateData("endDate", newEndDate);

    //let { startDate } = e.appointmentData;

    form.option({
      labelMode: "static",
      labelLocation: "top",
      colCountByScreen: { lg: 2, xs: 1 },
    });

    let nhsNo = "";
    let tNo = "";
    let emptyNhsNo = true;
    let emptyTNo = true;
    let reqAddLine1 = true;
    let reqPostcode = true;

    if (idsRequired === true) {
      emptyNhsNo = true;
      emptyTNo = true;

      if (appNhsNumber && appTNumber) {
        emptyNhsNo = false;
        emptyTNo = false;
      } else if (appNhsNumber && !appTNumber) {
        emptyTNo = false;
      } else if (!appNhsNumber && appTNumber) {
        emptyNhsNo = false;
      }
    } else {
      emptyNhsNo = false;
      emptyTNo = false;
    }

    if (addRequired === false) {
      reqAddLine1 = false;
      reqPostcode = false;
    }

    form.option("items", [
      {
        caption: "Appointment Details",
        itemType: "group",
        colSpan: 1,
        colCountByScreen: { lg: 2, xs: 1 },
        items: [
          {
            isRequired: true,
            colSpan: 1,
            editorType: dxDateBox,
            dataField: "startDate",
            editorOptions: {
              readOnly: startDateReadOnly,
              type: "datetime",
              displayFormat: "dd-MM-yyyy HH:mm",
              min: diaryMinDate,
              max: diaryMaxDate,
              pickerType: "list",
              showAnalogClock: false,
              showDropDownButton: true,
              stylingMode: "outlined",
              width: "100%",
              value: newStartDate,
              calendarOptions: {
                firstDayOfWeek: 1,
              },
              onValueChanged(args) {
                let minTime;
                let maxTime;
                let minMinutes = 0;
                let maxMinutes = 0;
                let minTimeHour = data.diaryStartTime;
                let maxTimeHour = data.diaryEndTime;

                //separate min date
                let minDayOnly = format(new Date(args.value), "d");
                let minMonthOnly = format(new Date(args.value), "MM") - 1;
                let minYearOnly = format(new Date(args.value), "yyyy");
                //separate max date
                let maxDayOnly = format(new Date(args.value), "d");
                let maxMonthOnly = format(new Date(args.value), "MM") - 1;
                let maxYearOnly = format(new Date(args.value), "yyyy");

                let startTimeLength = data.diaryStartTime.toString();
                let endTimeLength = data.diaryEndTime.toString();

                if (startTimeLength.length > 2) {
                  minTimeHour = startTimeLength.substring(0, 2);
                  minMinutes = 30;
                }

                if (endTimeLength.length > 2) {
                  maxTimeHour = endTimeLength.substring(0, 2);
                }

                minTime = formatISO9075(
                  new Date(
                    minYearOnly,
                    minMonthOnly,
                    minDayOnly,
                    minTimeHour,
                    minMinutes,
                    0
                  )
                );

                maxTime = formatISO9075(
                  new Date(
                    maxYearOnly,
                    maxMonthOnly,
                    maxDayOnly,
                    maxTimeHour,
                    appTimeSlot,
                    0
                  )
                );

                //let minTimeOnly = `${dateOnly}T${timeOnly}Z`;
                let dateValue = new Date(args.value);
                let minTimeOnly = new Date(minTime);
                let maxTimeOnly = new Date(maxTime);
                let isDateLess = isBefore(dateValue, minTimeOnly);
                let isDateMore = isAfter(dateValue, maxTimeOnly);
                newStartDate = dateValue;

                if (data.restrictedTimes === true) {
                  let isAfterTwelve = compareAsc(
                    new Date(newStartDate),
                    new Date(`${dateOnly}T10:45:00.000Z`)
                  );

                  if (isAfterTwelve === 1) {
                    appTimeSlot = 30;
                  } else {
                    appTimeSlot = 15;
                  }
                }

                const endDate = addMinutes(new Date(newStartDate), appTimeSlot);
                form.updateData("endDate", endDate);

                if (isDateLess) {
                  showToast(
                    "Warning",
                    `Date/Time selected cannot be before/after todays date and outside of diary time slots...`,
                    "warning"
                  );
                  form.updateData("startDate", minTimeOnly);
                  const endDate = addMinutes(
                    new Date(minTimeOnly),
                    appTimeSlot
                  );
                  form.updateData("endDate", endDate);
                }

                if (isDateMore) {
                  showToast(
                    "Warning",
                    `Date/Time selected cannot be before/after todays date and outside of diary time slots...`,
                    "warning"
                  );
                  form.updateData("startDate", maxTimeOnly);
                  const endDate = addMinutes(
                    new Date(maxTimeOnly),
                    appTimeSlot
                  );
                  form.updateData("endDate", endDate);
                }
              },
            },
          },
          {
            isRequired: true,
            colSpan: 1,
            name: "End Date",
            dataField: "endDate",
            editorType: dxDateBox,
            editorOptions: {
              stylingMode: "outlined",
              displayFormat: "dd-MM-yyyy HH:mm",
              width: "100%",
              type: "datetime",
              value: newEndDate,
              readOnly: endDateReadOnly,
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Full Triage?",
            },
            name: "fTriage",
            dataField: "fTriage",
            editorType: dxSelectBox,
            editorOptions: {
              hint: "new patient?",
              stylingMode: "outlined",
              placeholder: "Please Select",
              dataSource: ["Yes", "No"],
              itemTemplate(itemData) {
                return `${itemData}`;
              },
            },
          },
          {
            isRequired: true,
            colSpan: 1,
            label: {
              text: "Who is referring?",
            },
            name: "wRefer",
            dataField: "wRefer",
            editorType: dxTextBox,
            editorOptions: {
              hint: "Name/Location",
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.wRefer ? e.appointmentData.wRefer : null,
              onValueChanged(args) {
                if (args.value.length > 0 && args.value.length < 2) {
                  showToast(
                    "Error",
                    `Minimum of 2 characters required ...`,
                    "warning"
                  );
                  form.updateData("wRefer", "");
                }
              },
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Obs",
            },
            name: "obs",
            dataField: "obs",
            editorType: dxSelectBox,
            editorOptions: {
              stylingMode: "outlined",
              placeholder: "Please Select",
              dataSource: ["Required", "Not required"],
              itemTemplate(itemData) {
                return `${itemData}`;
              },
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "ECG",
            },
            name: "ecg",
            dataField: "ecg",
            editorType: dxSelectBox,
            editorOptions: {
              stylingMode: "outlined",
              placeholder: "Please Select",
              dataSource: ["Required", "Not required"],
              itemTemplate(itemData) {
                return `${itemData}`;
              },
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Specific Bloods",
            },
            name: "sBloods",
            dataField: "sBloods",
            editorType: dxTextArea,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.sBloods
                ? e.appointmentData.sBloods
                : null,
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Reason for attendance",
            },
            name: "rAttend",
            dataField: "rAttend",
            editorType: dxTextArea,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.rAttend
                ? e.appointmentData.rAttend
                : null,
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Colour Categories",
            },
            name: "colour",
            dataField: "colour",
            editorType: dxSelectBox,
            editorOptions: {
              stylingMode: "outlined",
              placeholder: "Please Select",
              dataSource: [
                "None",
                "New - Red",
                "ED FU - Turquoise",
                "FU Clinical Review - Orange",
                "FU Scan - Purple",
                "Procedure - Pink",
                "Virtual Assessment - Yellow",
              ],
              itemTemplate(itemData) {
                return `${itemData}`;
              },
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Owner",
            },
            name: "owner",
            dataField: "owner",
            editorType: dxTextBox,
            editorOptions: {
              hint: "Dr/Consultant",
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.owner ? e.appointmentData.owner : null,
              onValueChanged(args) {
                if (args.value.length > 0 && args.value.length < 2) {
                  showToast(
                    "Error",
                    `Minimum of 2 characters required ...`,
                    "warning"
                  );
                  form.updateData("owner", "");
                }
              },
            },
          },
        ],
      },
      {
        caption: "Patient Details",
        itemType: "group",
        groupName: "patientDetails",
        colSpan: 1,
        colCountByScreen: { lg: 2, xs: 1 },
        items: [
          {
            itemType: "group",
            groupName: "patientId",
            colSpan: 2,
            colCountByScreen: { lg: 2, xs: 1 },
            items: [
              {
                isRequired: emptyTNo,
                dataField: "tNumber",
                name: "tNumber",
                editorType: dxTextBox,
                colSpan: 1,
                label: {
                  text: "T-Number",
                },
                editorOptions: {
                  stylingMode: "outlined",
                  mask: "TNNNNNNN",
                  maskRules: {
                    T: /[Tt]/,
                    N: /[0-9]/,
                  },
                  value: e.appointmentData.tNumber
                    ? e.appointmentData.tNumber
                    : null,
                  onValueChanged(args) {
                    tNo = args.value;
                    tNo = tNo.toUpperCase();

                    if (idsRequired) {
                      let passed = false;
                      if (tNo !== "" && nhsNo === "") {
                        form.itemOption(
                          "patientId.nhsNumber",
                          "isRequired",
                          false
                        );
                        passed = true;
                      } else if (tNo === "" && nhsNo === "") {
                        form.itemOption(
                          "patientId.nhsNumber",
                          "isRequired",
                          true
                        );
                        form.itemOption(
                          "patientId.tNumber",
                          "isRequired",
                          true
                        );
                      }

                      if (passed) {
                        form.updateData("tNumber", tNo);
                      }
                    }
                  },
                },
              },
              {
                isRequired: emptyNhsNo,
                dataField: "nhsNumber",
                name: "nhsNumber",
                editorType: dxTextBox,
                colSpan: 1,
                label: {
                  text: "NHS-Number",
                },
                editorOptions: {
                  stylingMode: "outlined",
                  mask: "000 000 0000",
                  value: e.appointmentData.nhsNumber
                    ? e.appointmentData.nhsNumber
                    : null,
                  onValueChanged(args) {
                    nhsNo = args.value;

                    if (idsRequired) {
                      let passed = false;

                      if (nhsNo !== "" && tNo === "") {
                        form.itemOption(
                          "patientId.tNumber",
                          "isRequired",
                          false
                        );
                        passed = true;
                      } else if (nhsNo === "" && tNo === "") {
                        form.itemOption(
                          "patientId.nhsNumber",
                          "isRequired",
                          true
                        );
                        form.itemOption(
                          "patientId.tNumber",
                          "isRequired",
                          true
                        );
                      }

                      if (passed) {
                        if (isValidNhsNumber(nhsNo)) {
                          form.updateData("nhsNumber", nhsNo);
                        } else {
                          nhsNo = "";
                          showToast(
                            "Error",
                            `${nhsNo} is an invalid NHS number! Please check and try again...`,
                            "warning"
                          );
                          form.updateData("nhsNumber", "");
                        }
                      }
                    }
                  },
                },
              },
            ],
          },
          {
            isRequired: true,
            colSpan: 1,
            label: {
              text: "First Name",
            },
            name: "firstName",
            dataField: "firstName",
            editorType: dxTextBox,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.firstName
                ? e.appointmentData.firstName
                : null,
              onValueChanged(args) {
                if (args.value.length > 0 && args.value.length < 2) {
                  showToast(
                    "Error",
                    `Minimum of 2 characters required ...`,
                    "warning"
                  );
                  form.updateData("firstName", "");
                }
              },
            },
          },
          {
            isRequired: true,
            colSpan: 1,
            label: {
              text: "Surname",
            },
            name: "surName",
            dataField: "surName",
            editorType: dxTextBox,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.surName
                ? e.appointmentData.surName
                : null,
              onValueChanged(args) {
                if (args.value.length > 0 && args.value.length < 2) {
                  showToast(
                    "Error",
                    `Minimum of 2 characters required ...`,
                    "warning"
                  );
                  form.updateData("surName", "");
                }
              },
            },
          },
          {
            isRequired: true,
            colSpan: 2,
            label: {
              text: "Date of Birth",
            },
            name: "dob",
            dataField: "dob",
            editorType: dxDateBox,
            showDropDownButton: "false",
            editorOptions: {
              stylingMode: "outlined",
              displayFormat: "dd/MM/yyyy",
              placeholder: "dd/mm/yyyy",
              pickerType: "list",
              min: minDob,
              max: maxDob,
              width: "100%",
              type: "date",
              icon: "event",
              value: e.appointmentData.dob ? e.appointmentData.dob : null,
              calendarOptions: {
                firstDayOfWeek: 1,
              },
            },
          },
          {
            isRequired: reqAddLine1,
            colSpan: 1,
            label: {
              text: "Address Line 1",
            },
            name: "addLine1",
            dataField: "addLine1",
            editorType: dxTextArea,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.addLine1
                ? e.appointmentData.addLine1
                : null,
              onValueChanged(args) {
                if (args.value.length > 0 && args.value.length < 2) {
                  showToast(
                    "Error",
                    `Minimum of 2 characters required ...`,
                    "warning"
                  );
                  form.updateData("addLine1", "");
                }
              },
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Address Line 2",
            },
            name: "addLine2",
            dataField: "addLine2",
            editorType: dxTextArea,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.addLine2
                ? e.appointmentData.addLine2
                : null,
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Town/City",
            },
            name: "townCity",
            dataField: "townCity",
            editorType: dxTextBox,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.townCity
                ? e.appointmentData.townCity
                : null,
            },
          },
          {
            isRequired: reqPostcode,
            colSpan: 1,
            label: {
              text: "PostCode",
            },
            name: "postcode",
            dataField: "postcode",
            editorType: dxTextBox,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              mask: "LBBB NLL",
              maskRules: {
                B: /[A-Z a-z 0-9]/,
                L: /[A-Z a-z]/,
                N: /[0-9]/,
              },
              value: e.appointmentData.postcode
                ? e.appointmentData.postcode
                : null,
              onValueChanged(args) {
                let char = args.value;
                char = char.toUpperCase();
                form.updateData("postcode", char);
              },
            },
          },
          {
            isRequired: false,
            colSpan: 1,
            label: {
              text: "Preferred contact number",
            },
            name: "contactNumber",
            dataField: "contactNumber",
            editorType: dxTextBox,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.contactNumber
                ? e.appointmentData.contactNumber
                : null,
            },
          },
        ],
      },
      {
        caption: "Other Details",
        itemType: "group",
        colSpan: 2,
        items: [
          {
            label: {
              text: "Notes",
            },
            name: "notesField",
            dataField: "notesField",
            editorType: dxTextArea,
            editorOptions: {
              stylingMode: "outlined",
              width: "auto",
              type: "text",
              value: e.appointmentData.notesField
                ? e.appointmentData.notesField
                : null,
            },
          },
        ],
      },
    ]);
  };

  const isValidMin = (value) => {
    let valid = false;
    if (value.length < 2) {
      valid = true;
    }
    return valid;
  };

  const checkCurrentApp = (start, finish, limit) => {
    let startDate = new Date(start);
    let finishDate = new Date(finish);

    let addStartDate = addMinutes(startDate, diaryTimeSlot);
    let subFinishDate = subMinutes(finishDate, diaryTimeSlot);

    let addMiddleStartTime = addMinutes(startDate, diaryTimeSlot);

    let getStartEndTime = formatDate(addStartDate, "yyyy-MM-ddTHH:mm:ss.000Z");
    let getFinishStartTime = formatDate(
      subFinishDate,
      "yyyy-MM-ddTHH:mm:ss.000Z"
    );
    let getMiddleStartTime = formatDate(
      addMiddleStartTime,
      "yyyy-MM-ddTHH:mm:ss.000Z"
    );

    //let res = format(start, "hh:mm:ss");
    let startToString = start.toString();
    let filteredStart;
    let filteredMiddle;
    let filteredEnd;

    let timeMinOnly = startToString.substring(14, 16);

    if (timeMinOnly === "15" || timeMinOnly === "45") {
      filteredStart = data.appointments.filter((date) => {
        return date.startDate === getFinishStartTime;
      });

      /*filteredMiddle = data.appointments.filter((date) => {
        console.log("middle",date.startDate === getMiddleStartTime);
        return date.startDate === getMiddleStartTime;
      });*/

      filteredEnd = data.appointments.filter((date) => {
        return date.endDate <= getStartEndTime;
      });
    } else {
      filteredStart = data.appointments.filter((date) => {
        return date.startDate === start;
      });

      filteredEnd = data.appointments.filter((date) => {
        return date.endDate === finish && date.endDate <= finish;
      });
    }

    let compareDateResult = [];
    let compareStartDateResult;
    let compareMiddleDateResult;
    let compareEndDateResult;
    let caught = 0;
    //Compare the two dates and return -1 if the first date is after the second, 1 if the first date is before the second or 0 if dates are equal.
    if (filteredStart && filteredStart.length >= limit) {
      for (let i = 0; i < filteredStart.length; i++) {
        compareStartDateResult = compareDesc(
          new Date(start),
          new Date(filteredStart[i].startDate)
        );

        if (compareStartDateResult === 0) {
          compareDateResult.push(1);
        }
      }
    }

    if (filteredMiddle && filteredMiddle.length >= limit) {
      for (let i = 0; i < filteredMiddle.length; i++) {
        compareMiddleDateResult = compareDesc(
          new Date(getMiddleStartTime),
          new Date(filteredMiddle[i].startDate)
        );

        if (compareMiddleDateResult === 0) {
          compareDateResult.push(1);
        }
      }
    }

    if (filteredEnd && filteredEnd.length >= limit) {
      for (let i = 0; i < filteredEnd.length; i++) {
        compareEndDateResult = compareDesc(
          new Date(getFinishStartTime),
          new Date(filteredEnd[i].startDate)
        );

        if (compareEndDateResult === 0) {
          compareDateResult.push(1);
        }
      }
    }

    if (compareDateResult.indexOf(1) > -1) {
      caught = 1;
    }

    if (caught === 1) {
      return true;
    } else {
      return false;
    }
  };

  const checkCurrentAppUpdate = (id, key, limit) => {
    let canUpdate = false;

    const filtered = data.appointments.filter(
      (entry) => entry.startDate === key
    );

    if (filtered.length >= limit) {
      filtered.forEach(function (item, index) {
        if (item.id === id) {
          canUpdate = true;
        }
      });
    } else {
      canUpdate = true;
    }
    return canUpdate;
  };

  const onAdding = async (e) => {
    const query = {
      ...e.appointmentData,
      diaryId: params.diaryId,
      title: "Appointment",
      addedToPas: false,
      addedBy: name ? name : null,
      changedBy: name ? name : null,
    };
    let itIsRestricted = false;

    e.cancel = new Promise(async (resolve) => {
      const taken = await checkCurrentApp(
        e.appointmentData.startDate,
        data.limitPerApp
      );

      if( checkCell(e.appointmentData) ){
        showToast(
          "Limit reached",
          `please refresh the diary to display the latest appointments or select a different time slot..`,
          "info"
        );
        e.cancel = true;
      }else if (taken === false) {
        //check other variables
        if (isDinner(new Date(query.startDate)) === true) {
          e.cancel = true;
          resolve(false);
          showToast(
            "Warning",
            "Break slot is currently not available.",
            "warning"
          );
        } else if (isDinner2(new Date(query.startDate)) === true) {
          e.cancel = true;
          resolve(false);
          showToast(
            "Warning",
            "Break slot is currently not available.",
            "warning"
          );
        } else if (isDinner3(new Date(query.startDate)) === true) {
          e.cancel = true;
          resolve(false);
          showToast(
            "Warning",
            "Break slot is currently not available.",
            "warning"
          );
        } else if (data.restrictedTimes === true) {
          let stringStartDate = format(
            new Date(query.startDate),
            "yyyy-MM-dd'T'HH:mm:ss.'000Z'"
          );

          const getDay = new Date(query.startDate).getDay();
          const dateOnly = stringStartDate.substring(0, 10);
          const newRestrictedTimesWeekend = [];
          const newRestrictedTimes = [];
          let filteredTime = [];

          switch (getDay) {
            case 0:
              restrictedTimesWeekend.map((item) => {
                return newRestrictedTimesWeekend.push(`${dateOnly}${item}`);
              });

              filteredTime = newRestrictedTimesWeekend.filter(
                (entry) => entry === query.startDate
              );

              if (filteredTime.length > 0) {
                itIsRestricted = true;
              }
              break;
            case 6:
              restrictedTimesWeekend.map((item) => {
                return newRestrictedTimesWeekend.push(`${dateOnly}${item}`);
              });

              filteredTime = newRestrictedTimesWeekend.filter(
                (entry) => entry === query.startDate
              );

              if (filteredTime.length > 0) {
                itIsRestricted = true;
              }
              break;
            default:
              restrictedTimes.map((item) => {
                return newRestrictedTimes.push(`${dateOnly}${item}`);
              });

              filteredTime = newRestrictedTimes.filter(
                (entry) => entry === query.startDate
              );

              if (filteredTime.length > 0) {
                itIsRestricted = true;
              }
              break;
          }

          if (itIsRestricted === true) {
            e.cancel = true;
            resolve(false);
            showToast(
              "Warning",
              `${new Date(filteredTime[0])} slot is currently not available.`,
              "warning"
            );
          } else {
            const result = await apiFetch("add-appointment", "POST", query);

            if (result) {
              resolve(false);
              refresh(true);
            } else {
              showToast("Error", `Error saving appointment...`, "warning");
              resolve(true);
            }
          }
        } else if (data.restrictedDays === true) {
          const getDay = new Date(query.startDate).getDay();
          const dayOne = data ? data.rDays.dayOne : null;
          const dayTwo = data ? data.rDays.dayTwo : null;
          const dayThree = data ? data.rDays.dayThree : null;
          const dayFour = data ? data.rDays.dayFour : null;
          const dayFive = data ? data.rDays.dayFive : null;
          const daySix = data ? data.rDays.daySix : null;
          let dateToDisplay = format(new Date(query.startDate), "do MMM");

          const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          let dayName = days[getDay];

          switch (getDay) {
            case dayOne:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;

            case dayTwo:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;

            case dayThree:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;

            case dayFour:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;
            case dayFive:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;
            case daySix:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;
            default:
              const result = await apiFetch("add-appointment", "POST", query);
              if (result) {
                resolve(false);
                refresh(true);
              } else {
                showToast("Error", `Error saving appointment...`, "warning");
                resolve(true);
              }
              break;
          }
        } else {
          const result = await apiFetch("add-appointment", "POST", query);
          if (result) {
            resolve(false);
            refresh(true);
          } else {
            showToast("Error", `Error saving appointment...`, "warning");
            resolve(true);
          }
        }
      }
    });

    setEditingOptions((editingOptions) => {
      return { ...editingOptions, allowAdding: e.value };
    });
  };

  const onDeleting = async (e) => {
    const query = {
      id: e.appointmentData.id,
    };

    e.cancel = new Promise(async (resolve) => {
      const result = await apiFetch("delete-appointment", "PUT", query);

      if (result) {
        resolve(false);
        refresh(true);
      } else {
        showToast(
          "Error",
          `Error deleting appointment, unable to contact API please try again later...`,
          "warning"
        );
        resolve(true);
      }
    });

    setEditingOptions((editingOptions) => {
      return { ...editingOptions, allowDeleting: e.value };
    });
  };

  const onUpdating = async (e) => {
    const query = {
      ...e.newData,
      changedBy: name ? name : null,
    };

    let itIsRestricted = false;

    e.cancel = new Promise(async (resolve) => {
      const canUpdateBooking = await checkCurrentAppUpdate(
        query.id,
        query.startDate,
        data.limitPerApp
      );

      if( checkCell(e.newData) ){
        showToast(
          "Limit reached",
          `please refresh the diary to display the latest appointments or select a different time slot..`,
          "info"
        );
        e.cancel = true;
      }else if (canUpdateBooking === true) {
        //check other variables

        if (data.restrictedTimes === true) {
          const getDay = new Date(query.startDate).getDay();
          const dateOnly = query.startDate.substring(0, 10);
          const newRestrictedTimesWeekend = [];
          const newRestrictedTimes = [];
          let filteredTime = [];

          switch (getDay) {
            case 0:
              restrictedTimesWeekend.map((item) => {
                return newRestrictedTimesWeekend.push(`${dateOnly}${item}`);
              });

              filteredTime = newRestrictedTimesWeekend.filter(
                (entry) => entry === query.startDate
              );

              if (filteredTime.length > 0) {
                itIsRestricted = true;
              }
              break;
            case 6:
              restrictedTimesWeekend.map((item) => {
                return newRestrictedTimesWeekend.push(`${dateOnly}${item}`);
              });

              filteredTime = newRestrictedTimesWeekend.filter(
                (entry) => entry === query.startDate
              );

              if (filteredTime.length > 0) {
                itIsRestricted = true;
              }
              break;
            default:
              restrictedTimes.map((item) => {
                return newRestrictedTimes.push(`${dateOnly}${item}`);
              });

              filteredTime = newRestrictedTimes.filter(
                (entry) => entry === query.startDate
              );

              if (filteredTime.length > 0) {
                itIsRestricted = true;
              }
              break;
          }

          if (itIsRestricted === true) {
            e.cancel = true;
            showToast(
              "Warning",
              `${new Date(filteredTime[0])} slot is currently not available.`,
              "warning"
            );
          } else {
            const result = await apiFetch("edit-appointment", "PUT", query);
            if (result) {
              resolve(false);
              refresh(true);
            } else {
              showToast("Error", `Error saving appointment...`, "warning");
              resolve(true);
            }
          }
        } else if (isDinner(new Date(query.startDate)) === true) {
          e.cancel = true;
          showToast(
            "Warning",
            "Break slot is currently not available.",
            "warning"
          );
        } else if (isDinner2(new Date(query.startDate)) === true) {
          e.cancel = true;
          showToast(
            "Warning",
            "Break slot is currently not available.",
            "warning"
          );
        } else if (isDinner3(new Date(query.startDate)) === true) {
          e.cancel = true;
          showToast(
            "Warning",
            "Break slot is currently not available.",
            "warning"
          );
        } else if (data.restrictedDays === true) {
          const getDay = new Date(query.startDate).getDay();
          const dayOne = data ? data.rDays.dayOne : null;
          const dayTwo = data ? data.rDays.dayTwo : null;
          const dayThree = data ? data.rDays.dayThree : null;
          const dayFour = data ? data.rDays.dayFour : null;
          const dayFive = data ? data.rDays.dayFive : null;
          const daySix = data ? data.rDays.daySix : null;
          let dateToDisplay = format(new Date(query.startDate), "do MMM");

          const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];

          let dayName = days[getDay];

          switch (getDay) {
            case dayOne:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;

            case dayTwo:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;

            case dayThree:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;

            case dayFour:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;
            case dayFive:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;
            case daySix:
              e.cancel = true;
              showToast(
                "Warning",
                `${dayName} ${dateToDisplay} is currently not available.`,
                "warning"
              );
              break;
            default:
              const result = await apiFetch("edit-appointment", "PUT", query);
              if (result) {
                resolve(false);
                refresh(true);
              } else {
                showToast("Error", `Error saving appointment...`, "warning");
                resolve(true);
              }
              break;
          }
        } else {
          const result = await apiFetch("edit-appointment", "PUT", query);
          if (result) {
            resolve(false);
            refresh(true);
          } else {
            showToast("Error", `Error saving appointment...`, "warning");
            resolve(true);
          }
        }
      } else {
        showToast("Error", `Appointment limit reached...`, "warning");
        resolve(true);
      }
    });

    setEditingOptions((editingOptions) => {
      return { ...editingOptions, allowUpdating: e.value };
    });
  };

  const refresh = (input) => {
    if (input === true) {
      setTimeout(() => getAllAppointments(), 1750);
    }
  };

  const onDraggingEnd = (e) => {
    //alert("this ended!");
  };

  const onAdded = (e) => {
    showAddedToast(e);
  };

  const onUpdated = (e) => {
    showUpdatedToast(e);
  };

  const onDeleted = (e) => {
    showDeletedToast(e);
  };

  const showToast = (event, value, type) => {
    Notify({
      message: `${event} ${value}`,
      height: 100,
      width: 500,
      minWidth: 350,
      type: `${type}`,
      shading: false,
      closeOnClick: true,
      closeOnOutsideClick: true,
      displayTime: 3500,
      position: "center",
    });
  };

  const showAddedToast = (e) => {
    const displayDate = format(new Date(e.appointmentData.startDate), "PPPPpp");
    showToast(
      "Added",
      `'Date:- ${displayDate} Patient:- ${e.appointmentData.firstName} ${e.appointmentData.surName}'`,
      "success"
    );
  };

  const showUpdatedToast = (e) => {
    const displayDate = format(new Date(e.appointmentData.startDate), "PPPPpp");
    showToast(
      "Updated",
      `'Date:- ${displayDate} Patient:- ${e.appointmentData.firstName} ${e.appointmentData.surName}'`,
      "info"
    );
  };

  const showDeletedToast = (e) => {
    const displayDate = format(new Date(e.appointmentData.startDate), "PPPPpp");
    showToast(
      "Deleted",
      `'Date:- ${displayDate} Patient:- ${e.appointmentData.firstName} ${e.appointmentData.surName}'`,
      "warning"
    );
  };

  const showErrorToast = (e) => {
    const displayDate = format(new Date(e.appointmentData.startDate), "PPPPpp");
    showToast(
      "Error",
      `'Date:- ${displayDate} Patient:- ${e.appointmentData.firstName} ${e.appointmentData.surName}'`,
      "warning"
    );
  };

  const openToolTip = (e) => {
    if (schedulerRef && schedulerRef.current) {
      schedulerRef.current.instance.showAppointmentTooltip();
    } else {
      showToast("Error", `Error with schedulerRef`, "warning");
    }
  };

  const openNewForm = (e) => {
    if (schedulerRef && schedulerRef.current) {
      const cDate = new Date(schedulerRef.current.props.defaultCurrentDate);

      let day = cDate.getDate();
      let getCurrentYear = new Date(cDate).getFullYear();
      let startOfBst = lastSunday(getCurrentYear, 3);
      let endOfBst = lastSunday(getCurrentYear, 10);

      function lastSunday(year, month) {
        var date = new Date(year, month, 1, 12);
        let weekday = date.getDay();
        let dayDiff = weekday === 0 ? 7 : weekday;
        let lastSunday = date.setDate(date.getDate() - dayDiff);
        return date.toDateString();
      }

      function getMonday(d) {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(d.setDate(diff));
      }

      let beginningOfWeek = getMonday(cDate);
      let getDay = beginningOfWeek.getDay();
      let getBeginningDay = beginningOfWeek.getDate();
      let dStartTime = diaryStartTime;
      let dEndTime = diaryStartTime;
      let dStartTimeLen = dStartTime.toString();
      let startMin = 0;
      let appTimeCombined = 0;

      if (getBeginningDay < 10) {
        getBeginningDay = "0" + getBeginningDay;
      }

      if (dStartTime < 10) {
        if (dStartTimeLen.length > 2) {
          dStartTimeLen = dStartTimeLen.split(".");
          dStartTime = `0${dStartTimeLen[0]}`;
          dEndTime = `0${dStartTimeLen[0]}`;

          if (dStartTimeLen[1] === "5") {
            startMin = 30;
            appTimeCombined = cellDuration + startMin;
          }
        } else {
          dStartTime = `0${dStartTime}`;
          dEndTime = `0${dEndTime}`;
          startMin = `00`;
        }
      } else {
        dStartTime = `${dStartTime}`;
        dEndTime = `${dEndTime}`;
        startMin = `00`;
      }

      if (appTimeCombined === 60) {
        appTimeCombined = `00`;
        dEndTime = `0${dStartTimeLen[0] + 1}`;
      } else if (appTimeCombined === 45) {
        appTimeCombined = `45`;
      } else {
        appTimeCombined = cellDuration;
      }

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      let dayName = days[getDay];

      if (day < 10) {
        day = `0${day}`;
      }
      let month = cDate.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let hours = cDate.getHours();
      if (hours < 10) {
        hours = `0${hours}`;
      }
      let mins = cDate.getMinutes();
      if (mins < 10) {
        mins = `0${mins}`;
      }

      let newSD = `${cDate.getFullYear()}-${month}-${getBeginningDay}T${dStartTime}:${startMin}:00.000Z`;
      let newED = `${cDate.getFullYear()}-${month}-${getBeginningDay}T${dEndTime}:${appTimeCombined}:00.000Z`;

      if (
        isAfter(new Date(cDate), new Date(startOfBst)) &&
        isBefore(new Date(cDate), new Date(endOfBst))
      ) {
        let removeHr1 = subHours(new Date(newSD), 1);
        let removeHr2 = subHours(new Date(newED), 1);

        newSD = removeHr1;
        newED = removeHr2;
      }

      newSD = new Date(newSD);
      newED = new Date(newED);
      schedulerRef.current.instance.showAppointmentPopup(
        {
          startDate: newSD,
          endDate: newED,
          newAppBtn: true,
        },
        true
      );
    } else {
      showToast("Error", `Error with schedulerRef`, "warning");
    }
  };

  const delButton = (appointmentData, e) => {
    e.event.stopPropagation();

    let deleteConfirm = confirm("<i>Are you sure?</i>", "Confirm delete");

    if (schedulerRef && schedulerRef.current) {
      schedulerRef.current.instance.hideAppointmentPopup();
    }

    deleteConfirm.then((dialogResult) => {
      if (dialogResult) {
        if (schedulerRef && schedulerRef.current) {
          schedulerRef.current.instance.deleteAppointment(appointmentData);
        } else {
          showToast("Error", `Error with schedulerRef`, "warning");
        }
      } else {
        showToast("Cancelled", `'Delete cancelled'`, "info");
      }
    });
  };

  const addedToPASButton = (appointmentData, e) => {
    e.event.stopPropagation();

    if (schedulerRef && schedulerRef.current) {
      schedulerRef.current.instance.hideAppointmentPopup();
    }

    if (schedulerRef && schedulerRef.current) {
      schedulerRef.current.instance.updateAppointment(appointmentData, {
        ...appointmentData,
        ...{ addedToPas: true },
      });
    } else {
      showToast("Error", `Error with schedulerRef`, "warning");
    }
  };

  const removeFromPASButton = (appointmentData, e) => {
    e.event.stopPropagation();

    if (schedulerRef && schedulerRef.current) {
      schedulerRef.current.instance.hideAppointmentPopup();
    }

    if (schedulerRef && schedulerRef.current) {
      schedulerRef.current.instance.updateAppointment(appointmentData, {
        ...appointmentData,
        ...{ addedToPas: false },
      });
    } else {
      showToast("Error", `Error with schedulerRef`, "warning");
    }
  };

  const routeChange = () => {
    let path = `/home`;
    history.push(path);
  };

  /*const onContentReady = (e) => {
    e.component.scrollTo(new Date());
  };*/

  const isDinner = (date) => {
    const currentHours = date.getHours();
    const currentMinutes = date.getMinutes();

    return (
      currentHours >= lunchBreakStart &&
      currentHours <= lunchBreakStart &&
      currentMinutes < lunchBreak
    );
  };

  const isDinner2 = (date) => {
    const currentHours2 = date.getHours();
    const currentMinutes2 = date.getMinutes();

    return (
      currentHours2 >= lunchBreakStart2 &&
      currentHours2 <= lunchBreakStart2 &&
      currentMinutes2 < lunchBreak2
    );
  };

  const isDinner3 = (date) => {
    const currentHours3 = date.getHours();
    const currentMinutes3 = date.getMinutes();

    return (
      currentHours3 >= lunchBreakStart3 &&
      currentHours3 <= lunchBreakStart3 &&
      currentMinutes3 < lunchBreak3
    );
  };

  const onCurrentViewChange = (value) => {
    switch (value) {
      case "Work Week":
        value = "workWeek";
        setCurrentView("workWeek");
        break;
      case "Week":
        value = "week";
        setCurrentView("week");
        break;
      case "Month":
        value = "month";
        setCurrentView("month");
        break;
      case "Day":
        value = "day";
        setCurrentView("day");
        break;
      default:
        value = "week";
        setCurrentView("week");
        break;
    }
    return value;
  };

  const isIdle = useIdle({ timeToIdle: 300000 });

  function TimeCell(props) {
    const { text, date } = props.itemData;

    return <div>{text}</div>;
  }

  const renderTimeCell = (itemData) => <TimeCell itemData={itemData} />;

  const renderDateCell = (props) => {
    const displayDate = new Date(props.date);
    let getDay = displayDate.getDay();
    let getDate = displayDate.getDate();

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    let dayName = days[getDay];

    if (currentView === "month") {
      return (
        <div>
          <div>
            <span>{dayName}</span>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div>
          <span>{dayName}</span>
          <h5>{getDate}</h5>
        </div>
      </div>
    );
  };

  const renderDataCell = (itemData) => {
    //const CellTemplate = currentView === "month" ? DataCellMonth : DataCell;
    //const CellTemplate = DataCell;

    const getDay = new Date(itemData.startDate).getDay();
    let addCss = "";
    let displayTextDay = "";
    let text = new Date(itemData.startDate).getDate();
    let whichView = currentView;

    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    if (data.restrictedDays === true) {
      const dayOne = data ? data.rDays.dayOne : null;
      const dayTwo = data ? data.rDays.dayTwo : null;
      const dayThree = data ? data.rDays.dayThree : null;
      const dayFour = data ? data.rDays.dayFour : null;
      const dayFive = data ? data.rDays.dayFive : null;
      const daySix = data ? data.rDays.daySix : null;

      if (getDay === dayOne) {
        if (isDinner(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner2(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner3(new Date(itemData.startDate))) {
          addCss = "";
        } else {
          addCss = "disable-date";
        }
      }

      if (getDay === dayTwo) {
        if (isDinner(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner2(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner3(new Date(itemData.startDate))) {
          addCss = "";
        } else {
          addCss = "disable-date";
        }
      }

      if (getDay === dayThree) {
        if (isDinner(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner2(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner3(new Date(itemData.startDate))) {
          addCss = "";
        } else {
          addCss = "disable-date";
        }
      }

      if (getDay === dayFour) {
        if (isDinner(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner2(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner3(new Date(itemData.startDate))) {
          addCss = "";
        } else {
          addCss = "disable-date";
        }
      }

      if (getDay === dayFive) {
        if (isDinner(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner2(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner3(new Date(itemData.startDate))) {
          addCss = "";
        } else {
          addCss = "disable-date";
        }
      }

      if (getDay === daySix) {
        if (isDinner(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner2(new Date(itemData.startDate))) {
          addCss = "";
        } else if (isDinner3(new Date(itemData.startDate))) {
          addCss = "";
        } else {
          addCss = "disable-date";
        }
      }
    }

    if (currentView === "month") {
      displayTextDay = text;

      return (
        <div className={addCss}>
          <div>
            <span>{displayTextDay}</span>
          </div>
        </div>
      );
    }

    if (isDinner(itemData.startDate)) {
      addCss = addCss + " dinner";
    }

    if (isDinner2(itemData.startDate)) {
      addCss = addCss + " dinner";
    }

    if (isDinner3(itemData.startDate)) {
      addCss = addCss + " dinner";
    }

    return (
      <div className={addCss}>
        <div></div>
      </div>
    );
  };

  return (
    <>
      {data ? (
        <>
          <div className={"content-block"}>
            <div className={"title-con"}>
              <h2 className={"title-left"}>{data ? data.name : null}</h2>
              <div className={"title-right"}>
                <Button icon={"home"} onClick={routeChange} text={"Home"} />
              </div>
            </div>
          </div>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings diary-main"}>
              <style type="text/css" media="print">
                {" @page { size: portrait; } "}{" "}
              </style>
              {loading ? (
                <div className="loading">
                  <div className="loader-icon">
                    <LoadIndicator
                      id="large-indicator"
                      height={60}
                      width={60}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {error ? (
                    <>
                      <div>{errorMessage}</div>
                    </>
                  ) : (
                    <>
                      <Scheduler
                        adaptivityEnabled={true}
                        allDay={false}
                        allDayPanelMode={"hidden"}
                        appointmentComponent={bookings}
                        appointmentTooltipRender={bookingTooltip}
                        className={"diary-con"}
                        cellDuration={cellDuration}
                        currentView={currentView}
                        dataSource={data && data.appointments}
                        dataCellRender={renderDataCell}
                        //dateCellRender={renderDateCell}
                        defaultCurrentDate={newCurrentDate}
                        editing={editingOptions}
                        endDayHour={diaryEndTime}
                        firstDayOfWeek={1}
                        id={"diary"}
                        min={diaryMinDate}
                        max={diaryMaxDate}
                        maxAppointmentsPerCell={cellAppLimit}
                        onCurrentViewChange={onCurrentViewChange}
                        onAppointmentFormOpening={onAppointmentFormOpening}
                        onAppointmentAdding={onAdding}
                        onAppointmentAdded={onAdded}
                        onAppointmentUpdating={onUpdating}
                        onAppointmentUpdated={onUpdated}
                        onAppointmentDeleting={onDeleting}
                        onAppointmentDeleted={showDeletedToast}
                        onAppointmentClick={openToolTip}
                        onCellClick={onCellClick}
                        //onContentReady={onContentReady}
                        recurrenceEditMode="dialog"
                        recurrenceExceptionExpr=""
                        recurrenceRuleExpr=""
                        ref={schedulerRef}
                        shadeUntilCurrentTime={false}
                        showCurrentTimeIndicator={false}
                        showAllDayPanel={false}
                        startDayHour={diaryStartTime}
                        //timeCellRender={renderTimeCell}
                        views={views}
                      />

                      <AppointmentDragging onDragEnd={onDraggingEnd} />
                      <div className={"content-block"}>
                        <div className={"diary-add"}>
                          <div className={"key-left keyCon"}>
                            <div className={"keyRow"}>Key:</div>

                            <div className={"keyRow"}>
                              <ul className={"keyCol"}>
                                {" "}
                                <li className={"keyRow"}>
                                  <div
                                    className={"keySquares iconKeyWhite"}
                                  ></div>
                                  <div>Appointments per cell :</div>
                                </li>
                                <li className={"keyRow"}>
                                  <div
                                    className={"keySquares iconKeyAdded"}
                                  ></div>{" "}
                                  <div>Manually added to PAS</div>{" "}
                                </li>
                                <li className={"keyRow"}>
                                  <div
                                    className={"keySquares iconKeyDefault"}
                                  ></div>{" "}
                                  <div>Booked appointment</div>{" "}
                                </li>
                              </ul>
                              <ul className={"keyCol"}>
                                <li className={"keyRow"}>
                                  <div
                                    className={"keySquares iconKeyWhite"}
                                  ></div>
                                  <div>
                                    <strong>{data.limitPerApp}</strong>
                                  </div>
                                </li>
                                <li className={"keyRow"}>
                                  <div
                                    className={"keySquares iconKeyYellow"}
                                  ></div>{" "}
                                  <div>Break Slot</div>{" "}
                                </li>
                                <li className={"keyRow"}>
                                  <div
                                    className={"keySquares iconDisable"}
                                  ></div>{" "}
                                  <div>Disabled Slot</div>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className={"key-right"}>
                            <div className={"idleWarningCon"}>
                              <div>
                                {isIdle ? (
                                  <div className={"idleWarning"}>
                                    Please refresh before updating the diary.
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <Button
                            activeStateEnabled={false}
                            icon={"refresh"}
                            hint={"Refresh"}
                            stylingMode={"contained"}
                            type={"default"}
                            useSubmitBehavior={false}
                            onClick={getAllAppointments}
                          />
                          <Button
                            activeStateEnabled={false}
                            icon={"plus"}
                            //text={"Add Appointment"}
                            hint={"Add Appointment"}
                            stylingMode={"contained"}
                            type={"default"}
                            useSubmitBehavior={false}
                            onClick={openNewForm}
                          />
                          <Button
                            activeStateEnabled={false}
                            icon={"print"}
                            //text={"print diary"}
                            hint={"Print Diary"}
                            stylingMode={"contained"}
                            type={"default"}
                            useSubmitBehavior={false}
                            onClick={handlePrint}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ textAlign: "center" }}>
            <h3>Loading please wait..</h3>
          </div>
        </>
      )}
    </>
  );
};

export default Diary;
